
  import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue';
  import { getUserInfo, editUser } from '@/api/userinfo';
  import { getAllOrganization } from '@/api/organization';
  import { useRouter, useRoute } from 'vue-router';
  import { ElMessage } from 'element-plus';
  import { validatePassword } from '@/utils/validate';

  export default defineComponent({
    name: 'userManage',
    setup(props, { emit }) {
      console.log(props);
      const route = useRoute();
      const router = useRouter();
      const userId: any = route.query.id;
      const state = reactive({
        direction: 'rtl',
        editForm: {
          userName: '',
          organizationIds: '',
          jobNo: '',
          idNo: '',
          phoneNum: '',
          root: false,
          password: '',
        },
        rules: {
          userName: [
            {
              required: true,
              message: '请输入用户姓名',
              trigger: 'blur',
            },
          ],
          organizationIds: [
            {
              required: true,
              message: '请选择组织',
              trigger: 'change',
            },
          ],
          jobNo: [
            {
              userName: '请输入工号',
              trigger: 'blur',
            },
          ],
          phoneNum: [
            {
              required: true,
              userName: '请输入电话号码',
              trigger: 'blur',
            },
          ],
        },
        ruleForm: null,
        organizationData: [],
      });
      const handleSubmit = () => {
        const form: any = state.ruleForm;
        form.validate(async (valid: any) => {
          if (valid) {
            const params = { ...state.editForm };
            const res: any = await editUser(userId, params);
            if (res.code === 0) {
              ElMessage({
                message: '编辑成功',
                type: 'success',
              });
              router.push('/userManage');
            } else {
              ElMessage({
                message: res.msg,
                type: 'warning',
              });
            }
          }
        });
      };
      const resetForm = () => {
        const form: any = state.editForm;
        form.resetFields();
      };
      const handleback = () => {
        router.push('/userManage');
      };

      const getOrganizationData = async () => {
        const res = await getAllOrganization();
        state.organizationData = res.data;
        console.log('cicicici');
        console.log(state.organizationData);
      };

      const getUserDetail = async () => {
        const res = await getUserInfo(userId);
        console.log(res);
        state.editForm = res.data;
        state.editForm.organizationIds = res.data.organizationList;
      };

      onBeforeMount(() => {
        getUserDetail();
        getOrganizationData();
      });

      return {
        ...toRefs(state),
        handleSubmit,
        resetForm,
        handleback,
        getOrganizationData,
        userId,
      };
    },
  });
